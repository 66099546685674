import { FC } from 'react';

import useDC from '@dc/useDC';

import GTMTag from './GTMTag';

const GTM: FC = () => {
  const { customerId, accountId } = useDC();
  return <GTMTag customerId={customerId?.toString()} accountId={accountId} />;
};

export default GTM;
